/*Blue Background: #00af91
  Blue: #007965
  Orange: #FF4001
  Orange2: #f58634
  Pink: #F9CACC
  Yellow: #F39200
  Yellow2: #ffcc29
*/

.App {
  text-align: center;
  font-size: 125%;
  font: Montserrat;
}

div {
  white-space: pre-wrap;
}

p,li{
  color: #2e2e2e;
}


.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.links {
  text-decoration: none;
}

.profile {
  height: 20vmin;
}

.hov_effect:hover {
  opacity: 0.8;
}

.bg-pink {
  background: #F9CACC;
}

.bg-blue {
  background: #0DFFA6;
}

.bg-orange {
  background: #f58634;
}

.inset-shadow {
  -moz-box-shadow: inset 0 -10px 10px -10px #575757;
  -webkit-box-shadow: inset 0 -10px 10px -10px #444444;
  box-shadow: inset 0 -10px 20px -10px #707070;
}

.humanbody {
  height: 50vmin;
}


.btn:focus {
  outline: none;
  box-shadow: none;
}

/* .btn:hover {
  opacity: 0.8;
} */

.card {
  min-width: fit-content;
  border: none;
  background: rgb(238, 238, 238);
}


.body-part {
  height: 15vmin;
  width: auto;
}

.option-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 5px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: 10px;
  border: none;
  max-width: fit-content;
  min-height: fit-content;
  background: white;
}

.option-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.pain-symbol {
  width: fit-content;
}

.opacity-50:hover {
  opacity: 0.5;
}

.contact-us {
  font-size: 75%;
}

.contact-btn {
  width: 13vh;
  position: fixed;
  z-index: 1000;
  bottom: 3vmin;
  right: 3vmin;
}

.contact-btn:hover {
  color: #ffcc29;
}

.pointer:hover {
  cursor: pointer;
}

input[type=checkbox] {
  position: relative;
  margin-right: 0px;
}

.checkboxes label {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}


/* _______scrollbar-designs__________*/


/* ::-webkit-scrollbar{
  width: 16px;
}
::-webkit-scrollbar-track{
  background: beige;
}
::-webkit-scrollbar-thumb{
  background: #ffcc29;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover{
  background: #F39200;
  border-radius: 25px;
} */

/*__________scrollbar-desings-end__________ */


/*_________selection-designs________*/
::selection {
  background: #00af91;
  color: white;
}

::-moz-selection {
  background: #00af91;
  color: white;
}

/*_________slection-designs-end________*/



@import url("https://fonts.googleapis.com/css?family=Montserrat");

#submitButton {
  padding: 15px 40px;
  background: #ffcc29;
  border: 0;
  color: rgb(68, 68, 68);
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 17px;
  letter-spacing: 3px;
  position: relative;
  transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: block;
  margin: 30px auto;
  border-radius: 50px;
}

#submitButton::after,
#submitButton::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#submitButton::after {
  width: 30px;
  height: 1px;
  background: rgb(68, 68, 68);
  transform: translateX(-3px);
  margin-top: 0px;
}

#submitButton::before {
  content: "";
  transform: rotate(-135deg) translateX(50%);
  width: 11px;
  height: 11px;
  background: transparent;
  border-left: 1px solid rgb(68, 68, 68);
  border-bottom: 1px solid rgb(68, 68, 68);
  margin-top: -1px;
}

#submitButton:hover {
  padding: 15px 60px 15px 20px;
}

#submitButton:hover::after,
#submitButton:hover::before {
  opacity: 1;
  right: 15px;
}


.display-4{
  font-size: inherit;
}

/* #Overview-Accordion{
} */

.accordion-card{
  background: transparent;
  padding: 0;
  border: transparent;   
  margin-bottom: 13px;
  display: block;
  position: relative;
  border-radius: 35px;
}
.accordion-header{
  background: transparent;
  padding: 0;
  border: transparent; 
}
.accordion-toggle{
  background: transparent;
  font-family: Arial;
  color: #5c5c5c;
  /* font-size: 1.3vmax; */
  background: linear-gradient(#f9cacc69, #ffcc2969);
  /* background: linear-gradient(to right, #ffcd298c 0%, #f9caccab 60%, #0079655e 100%); */
  padding: 15px 25px 15px 25px;
  text-decoration: none;
}

.accordion-toggle-middle{
  background: linear-gradient(#f9cacc69, #00af9259);
}
.accordion-toggle-result{
  background: linear-gradient(#ff020236, #00af9236);
}
.accordion-collapse{
  background: transparent;

}

.jumbotron{
  /* background-image: url(./assets/PNGs/242.jpg); */
  background-size: contain;
}

.bg-transparent{
  background: transparent;
}

.apply-filters{
  font: Montserrat;
  font-size: x-large;
  color: #007965;
}

.h2, .h3, .h5{
  color: #007965;
}

.h4{
  color: #f58634 ;
}

/* 
.h5{
  color: #007965;
} */

#when-to-see-doctor-div{
  /* border: 2px solid #f3920049; */
  /* background: #ff6a0013; */
  background: linear-gradient(#00af9241, #f5863441, #00af9241 );
  border-radius: 45px;
}

.a-link:hover{
  text-decoration: none;
}

/* ul li {
  background: #ffe5e5;
  padding: 5px;
  margin-left: 35px;
} */

/* ul li{
  list-style-type: "\2624"; 
} */

.disclaimer{
  font-size: smaller;
}